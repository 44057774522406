import React from 'react';
import { Link } from 'gatsby';

function LinkButtons({ links }) {
  if (!links || !links.length) {
    return null;
  }

  const commonClasses = 'w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md md:py-4 md:text-lg md:px-10';
  return (
    <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
      {
        links.map((item, i) => {
          const { secondary, label, href } = item;
          const isFirst = i === 0;
          const wrapperClasses = `rounded-me shadow ${isFirst ? '' : 'mt-3 sm:mt-0 sm:ml-3'}`;
          const linkClasses = `${commonClasses} ${secondary ? 'text-indigo-600 bg-white hover:bg-gray-50' : 'text-white bg-indigo-600 hover:bg-indigo-700'}`;
          return (
            <div className={wrapperClasses} key={label}>
              <Link to={href} className={linkClasses}>{label}</Link>
            </div>
          );
        })
      }
    </div>
  );
}

export default function HeroSplit({ img, headline, headlineHighlight, details, detailsTitle, links, alt }) {
  return (
    <div className="relative">
      <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span className="block xl:inline">{headline}</span>{' '}
            {!!headlineHighlight && <span className="block text-indigo-600 xl:inline">{headlineHighlight}</span>}
          </h1>
          {!!detailsTitle && (
            <p className="mt-3 max-w-md mx-auto font-bold text-xl text-gray-700 sm:text-2xl md:mt-5 md:max-w-3xl">
              {detailsTitle}
            </p>
          )}
          <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            {details}
          </p>
          <LinkButtons links={links} />
        </div>
      </div>
      <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src={img}
          alt={alt}
        />
      </div>
    </div>
  );
}