import React, { useState } from 'react';
import Container from '../components/layout/default/container';
import HeroSplit from '../components/marketing/hero-split';
import Faqs from '../components/marketing/faq';
import CtaBrand from '../components/marketing/cta-brand';
import SEO from '../components/layout/seo';
import AutoForm from '../components/form/auto-form';
import { statesOptions } from '../utils/helpers';
import Alert from '../components/alerts/alert';

  const contactForm = {
    type: 'post',
    postRoute: `/contact/start-league`,
    successNotification: 'We have received your message and will get back to you shortly.',
    fields: [
      {
        name: 'first_name',
        label: 'First Name',
        type: 'text',
        required: true,
      },
      {
        name: 'last_name',
        label: 'Last Name',
        type: 'text',
        required: true,
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        required: true,
      },
      {
        name: 'city',
        label: 'City',
        type: 'text',
        required: true,
      },
      {
        name: 'state',
        label: 'State',
        type: 'select',
        required: true,
        options: statesOptions(),
      },
      {
        id: 'phone',
        name: 'phone',
        label: 'Cell Phone',
        type: 'phone',
        required: true,
      },
      {
        name: 'league_for',
        label: 'Do you want to form a league for your:',
        type: 'select',
        required: true,
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 'workplace',
            label: 'Workplace',
          },
          {
            value: 'school',
            label: 'School',
          },
          {
            value: 'church',
            label: 'Church',
          },
          {
            value: 'other',
            label: 'Other',
          },
        ],
      },
      {
        name: 'league_for_other',
        label: 'Describe what you want to form a league for:',
        type: 'text',
        showIf: (formik) => {
          return formik.values.league_for === 'other';
        },
      },
    ],
    submitLabel: `Submit`,
  };

// markup
const IndexPage = () => {
  const [submittedForm, setSubmittedForm] = useState(false);

  const showMessage = () => {
    setSubmittedForm(true);
  };

  contactForm.successHandler = showMessage;

  return (
    <Container>
      <SEO title="Home" />
      <div>
        <HeroSplit
          img="/fbl-people.jpg"
          headline="Social Bowling Leagues For"
          headlineHighlight="Kids, Teens, Adults &amp; Families"
          detailsTitle="Just 9 Sessions &amp; A Ton Of Fun!"
          details="Leagues are forming near you, find a location and league in your community!"
          alt="Bowlers having fun"
          links={[
            {
              label: 'Find A Center Near Me',
              href: '/bowling-centers',
            },
          ]}
        />
      </div>
      <div>
        <Faqs
          questions={[
            {
              question: `How do I register?`,
              answer: `Find a bowling center near year with active leagues, and go through the simple registration process.`,
            },
            {
              question: `What if I'm not a good bowler?`,
              answer: `Fun Bowling Leagues are for individuals and families who want to have FUN! Leagues are designed for bowlers from beginners to expert to enjoy time bowling with others in a social bowling league. Browse our leagues page to see which leagues might be right for you.`,
            },
            {
              question: `How much does it cost?`,
              answer: `Each league is different. Leagues have a registration fee which includes your first session, along with a money back guarantee! We want to make sure that you enjoy the league that you register for and have NO risk making a decision to join.`,
            },
            {
              question: `When do leagues start?`,
              answer: `We have new leagues starting all of the time. Participating centers add new leagues when they have lane availability at their center. To see a list of current leagues, visit the leagues page, and find your local bowling center. Upcoming leagues, starting dates and league details will all be listed.`,
            },
            {
              question: `I can't find a league near me, what should I do?`,
              answer: `New bowling centers and leagues are being added all the time! Sign up to be notified when a league is added in your community.`,
            },
            {
              question: `What if I’d like to start a league in my community?`,
              answer: (
                <>
                  <p className="mb-4">
                    We have many bowlers who are interested in starting a league in their community. Often times a workplace, church or school
                    has interest in forming a league for just their community members. If you would like details, just complete this form and we’ll reach out to you.
                  </p>
                  {!submittedForm && (
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                      <AutoForm {...contactForm} />
                    </div>
                  )}
                  {submittedForm && <Alert type="success" title="Thank you!" body="We have received your message and we will get back to you as soon as possible." />}
                </>
              ),
            },
          ]}
        />
      </div>

      <div>
        <CtaBrand
          img="https://images.unsplash.com/photo-1463411563105-157075b06f96?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
          alt="Bowlers"
          headline="Ready to have fun?"
          headline2="Find a bowling league near you!"
          details="Browse through our nationwide bowling centers to find a league starting soon near you."
          href="/leagues"
          btnText="Find Leagues Now"
        />
      </div>
      
    </Container>
  );
}

export default IndexPage
